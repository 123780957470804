import React from 'react'
import styled from "@emotion/styled"
import { Flex, Box, Heading } from 'rebass'

const Container = styled(Flex)`
  background-color: #fff;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  position: sticky;
  z-index: 9;

  a {
    margin: 0 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  img {
    max-height: 46px;
  }
`

const Subheader = ({ name, children }) => (
  <Container p={[2, 3]} sx={{ top: [90, 100, 110] }}>
    <Heading variant='subheading'>{name}</Heading>
    <Box flex={1} />
    {children}
  </Container>
)

export default Subheader
