import React from 'react'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { graphql } from 'gatsby'
import { Heading, Flex, Box, Text } from 'rebass'
import Hero from '../components/base/Hero'
import BackedByIag from '../components/base/BackedByIag'
import Section from '../components/base/Section'
import Subheader from '../components/base/Subheader'
import Link from '../components/base/Link'
import FAQ from '../components/base/FAQ'

export default ({ data }) => (
  <>
    <HelmetDatoCms seo={data.datoCmsProduct.seoMetaTags} />
    <Subheader name={data.datoCmsProduct.name}>
      <Text sx={{ display: ['none', 'block'] }}>{data.datoCmsProduct.headerDescription}</Text>
      {data.datoCmsProduct.quoteUrl && <Link
        href={data.datoCmsProduct.quoteUrl}
        transfer
        variant="buttonSmall"
        as="a"
      >
        Quote
      </Link>}
    </Subheader>
    <Hero fluid={data.datoCmsProduct.heroImage.fluid}>
      <Heading as="h1" variant="hero.heading">{data.datoCmsProduct.heading}</Heading>
      <Heading as="h2" variant="hero.subheading">{data.datoCmsProduct.subheading}</Heading>
      {data.datoCmsProduct.quoteUrl && <Box>
        <Link
          href={data.datoCmsProduct.quoteUrl}
          transfer
          variant="button"
          as="a"
          sx={{ px: [2, 6], py: 2, display: ['block', 'inline-block'] }}
        >
          Quote
        </Link>
      </Box>}
      {!data.datoCmsProduct.quoteUrl && <Heading as="h2" variant="hero.subheading">Coming soon.</Heading>}
    </Hero>
    <Box mb={6}>
      {data.datoCmsProduct.sections.map(section => (
        <Box key={section.id} my={5}>
          <Section {...section} />
        </Box>
      ))}
      {/* Actions */}
      <Box variant='fixed' my={5}>
        <Heading as="h3" variant='heading'>
          {data.datoCmsProduct.quoteUrl ? 'Lets go' : 'Coming soon'}
        </Heading>
        <Flex my={1} mx={-1} flexDirection={['column', 'row']}>
          {data.datoCmsProduct.quoteUrl && <Link
            href={data.datoCmsProduct.quoteUrl}
            transfer
            variant="button"
            as="a"
            sx={{ flex: 1, m: 1 }}
          >
            Get a quote
          </Link>}
          {data.datoCmsProduct.statement && data.datoCmsProduct.statement.documents.map(doc => (
            <Link
              key={doc.id}
              href={doc.url}
              as="a"
              variant="outline"
              sx={{ flex: 1, m: 1 }}
            >
              {doc.name}
            </Link>
          ))}
          <Link to="/contact" variant="outline" sx={{ flex: 1, m: 1 }}>Ask a question</Link>
        </Flex>
      </Box>
      {/* FAQs */}
      {data.datoCmsProduct.faqs.length > 0 && (
        <Box variant='fixed' my={5}>
          <Heading as="h3" variant='heading'>FAQs</Heading>
          {data.datoCmsProduct.faqs.map(faq =>
            <Box key={faq.id} my={3}>
              <FAQ
                question={faq.question}
                answer={faq.answerNode.childMarkdownRemark.html}
                relevant={faq.relevantFaqs}
                slug={faq.slug}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
    <BackedByIag />
  </>
)

export const query = graphql`
  query ProductQuery($slug: String!) {
    datoCmsProduct(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      quoteUrl
      headerDescription
      name
      heading
      subheading
      heroImage {
        fluid(imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      sections {
        ... on DatoCmsDescription {
          id
          descriptionNode {
            childMarkdownRemark {
              html
            }
          }
          image {
            url
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsContent {
          id
          title
          contentNode {
            childMarkdownRemark {
              html
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsVideo {
          id
          video {
            provider
            providerUid
            title
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsFeature {
          id
          title
          feature1Node {
            childMarkdownRemark {
              html
            }
          }
          feature2Node {
            childMarkdownRemark {
              html
            }
          }
          feature3Node {
            childMarkdownRemark {
              html
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsCoverage {
          id
          title
          includedTitle
          includedNode {
            childMarkdownRemark {
              html
            }
          }
          notIncludedTitle
          notIncludedNode {
            childMarkdownRemark {
              html
            }
          }
          model {
            apiKey
          }
        }
      }
      statement {
        documents {
          id
          name
          url
        }
      }
      faqs {
        id
        slug
        question
        answerNode {
          childMarkdownRemark {
            html
          }
        }
        relevantFaqs {
          id
          slug
          question
        }
      }
    }
  }
`
